/******* screen-small.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/* roboto-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'RobotoRegular';
  font-style: normal;
  font-weight: 400;
  src: url('/extras/fonts/roboto-v30-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'RobotoBold';
  font-style: normal;
  font-weight: 700;
  src: url('/extras/fonts/roboto-v30-latin-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-condensed-500 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'RobotoCondensedMedium';
  font-style: normal;
  font-weight: 500;
  src: url('/extras/fonts/roboto-condensed-v27-latin-500.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* newsreader-600italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'NewsreaderSemiboldItalic';
  font-style: italic;
  font-weight: 600;
  src: url('/extras/fonts/newsreader-v20-latin-600italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
address {
  font-style: normal;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2014-08-26 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}
.unit > .body,
.unit > form > .body {
  width: 100%;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 10px;
  margin-bottom: 10px;
}
.lead {
  margin-bottom: 0 !important;
}
#edit .line {
  padding: 4px 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #3a2d29;
  color: #3a2d29;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #3a2d29 !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 211;
  color: #3a2d29;
  font-family: 'RobotoRegular', helvetica, sans-serif;
  line-height: 1.18181818;
  font-size: 22px;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #404040;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset,
#disp .part.recaptcha-info {
  padding: 0.2em 0 0.5em;
}
#disp .part.recaptcha-info {
  width: 100%;
  margin: 0;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: normal;
  font-family: 'RobotoBold', helvetica, sans-serif;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding: 0 15px 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  float: right;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #404040;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
.srch .text {
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #bfbfbf;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  padding-bottom: 0.8em;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #3a2d29;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #3a2d29;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
.grow {
  text-transform: none !important;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 0;
}
.table--headline th {
  font-weight: normal;
  font-family: 'RobotoBold', helvetica, sans-serif;
}
.table td {
  padding: 5px 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: normal;
  font-family: 'RobotoBold', helvetica, sans-serif;
}
@media (max-width: 767px) {
  .table {
    font-size: 2.86831812vw;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
html,
body {
  min-height: 100%;
  height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(58, 45, 41, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  width: 100%;
  background-color: transparent;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 100%;
  width: 1760px;
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
#home {
  position: absolute;
  left: 50%;
  top: 20px;
  z-index: 2;
  width: 394px;
}
.logo {
  width: 100%;
  height: auto;
}
#head {
  float: left;
  width: 100%;
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  color: #3a2d29;
  font-family: 'RobotoRegular', helvetica, sans-serif;
  line-height: 1.18181818;
  font-size: 22px;
  letter-spacing: 0.025em;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--navigation {
  background: #3a2d29;
}
.section--content {
  background-size: 280px 189px;
  background-position: 30px 40px;
  background-repeat: no-repeat;
  background-image: url(/images/original.png);
}
.section--south {
  background: #ebbca0;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  margin-top: 10px;
}
.section--south a {
  color: #fff;
}
.section--south a:hover,
.section--south a:focus {
  color: #3a2d29;
}
.section--footer {
  margin-top: 20px;
  background: #3a2d29;
  color: #fff;
  font-family: 'RobotoCondensedMedium', helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
}
.section--footer a {
  color: #fff;
  text-decoration: none;
}
.section--footer a:hover,
.section--footer a:focus {
  color: #ebbca0;
}
.moodcontent {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  z-index: 2;
}
.service_mymuerren {
  float: right;
  width: 80px;
  height: 26px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/service-muerren.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
.maincontent {
  float: left;
  width: 100%;
  margin-top: 40px;
}
.servicewidth {
  float: left;
  margin-top: 25px;
}
.servicewidth .meta {
  padding: 0 10px;
  text-transform: uppercase;
}
.servicewidth .meta:first-child {
  padding-left: 0;
}
.servicewidth .meta:last-child {
  padding-right: 0;
}
.footarea {
  float: left;
  width: 100%;
}
.footunit {
  float: left;
  width: 100%;
}
.vcard {
  float: left;
  width: 100%;
}
.vcard .org,
.vcard .tel {
  display: block;
}
.copyright {
  float: left;
  width: 100%;
}
.year {
  float: left;
  width: 100%;
}
#cmsbox {
  float: left;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
#cmsbox .meta.auth {
  margin-top: 26px;
}
.toplink {
  position: fixed;
  right: 4%;
  bottom: -44px;
  width: 44px;
  height: 44px;
  z-index: 10;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/toplink.svg);
  cursor: pointer;
  display: none;
  transition: all 0.2s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.toplink.cb-goto-top-visible {
  bottom: 20px;
}
@media (max-width: 1024px) {
  .toplink {
    display: block;
  }
}
.pfad {
  position: absolute;
  left: 0;
  top: 10px;
  z-index: 2;
  font-size: 14px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
.pfad a {
  text-transform: lowercase;
  text-decoration: none;
}
.pfad a:hover,
.pfad a:focus {
  color: #3a2d29;
}
.pfad a:before {
  content: '/';
}
@media (max-width: 1024px) {
  .pfad {
    font-size: 12px;
  }
}
#expo {
  float: left;
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 33.33333333%;
}
#slides {
  overflow: hidden;
  position: relative;
  max-width: 100% !important;
  max-height: 10000px !important;
  z-index: 1;
}
#slides .slide {
  width: 100%;
}
#slides img {
  display: block;
  width: 100%;
  max-width: 100% !important;
  max-height: 10000px !important;
}
#expo div.link {
  display: none;
}
h1,
h2 {
  font-size: 48px;
  line-height: 1.04166667;
  font-weight: normal;
  font-family: 'NewsreaderSemiboldItalic', serif;
  letter-spacing: 0.01em;
}
h3 {
  font-size: 30px;
  line-height: 1.06666667;
  font-weight: normal;
  font-family: 'NewsreaderSemiboldItalic', serif;
  letter-spacing: 0.01em;
}
strong {
  font-weight: normal;
  font-family: 'RobotoBold', helvetica, sans-serif;
}
.text {
  overflow-wrap: normal;
  word-wrap: normal;
  hyphens: none;
}
.open,
.load {
  overflow-wrap: normal;
  word-wrap: normal;
  hyphens: none;
}
a {
  color: #3a2d29;
  text-decoration: underline;
}
a:hover,
a:focus {
  color: #ebbca0;
}
div.area {
  display: flex;
  flex-wrap: wrap;
}
div.south {
  width: 100%;
  margin: 4px 0;
  text-align: center;
}
#edit div.south {
  min-height: 40px;
}
div.south div.unit {
  width: 100%;
  margin: 0;
}
div.south div.unit div.part {
  width: 100%;
  margin: 0;
}
div.fold div.ctrl {
  display: none;
}
.text-section {
  float: left;
  width: 100%;
}
.list--bullet {
  padding-left: 0;
  list-style-type: none;
}
.list--bullet li {
  padding-left: 24px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered li {
  margin-left: 16px;
}
div.load {
  background: none !important;
}
div.load + div.load {
  margin-top: -10px;
}
@media (max-width: 1024px) {
  div.load + div.load {
    margin-top: -5px;
  }
}
div.load a.load {
  text-decoration: none;
}
div.link a.open {
  float: left;
  font-family: 'RobotoCondensedMedium', helvetica, sans-serif;
  text-transform: uppercase;
  color: #ebbca0;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
  border: 5px solid #ebbca0;
  padding: 22px 50px;
  border-radius: 10px;
  min-width: 280px;
  box-sizing: border-box;
  background-size: 12px 12px;
  background-position: 19px 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-plus-brown-light.svg);
}
@media (max-width: 1024px) {
  div.link a.open {
    padding: 12px 30px;
    min-width: 140px;
    background-size: 10px 10px;
    background-position: 11px 50%;
  }
}
div.link a.open:hover,
div.link a.open.fold-toggle--open {
  background-color: #ebbca0;
  background-image: url(/images/icon-plus-white.svg);
  color: #fff;
}
div.link a.open:active {
  background-color: #e29e76;
}
/******* forms.less 2014-10-16 *******/
/* Changelog

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 1.18181818em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  margin-top: 0px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #404040;
  background-color: #fff;
  font-weight: normal;
  font-family: 'RobotoRegular', helvetica, sans-serif;
  font-size: 22px;
  font-size: 2.2pxrem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.1em 3px;
  min-height: 1.18181818em;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 22px;
  font-size: 2.2pxrem;
  color: #444;
  font-weight: normal;
  font-family: 'RobotoRegular', helvetica, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #8c8c8c;
}
input.text[disabled],
textarea[disabled] {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #99b5de;
  box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #c7d7ed;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #c7d7ed;
}
input.submit,
button.cb-hybrid {
  float: left;
  display: block;
  background-color: #3a2d29;
  color: #fff;
  text-align: center;
  text-decoration: none;
  min-height: 40px;
  border-radius: 0;
  padding: 0 20px;
  -webkit-appearance: none;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  color: #fff;
  background-color: #43342f;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  background-color: #1c1614;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
  width: 100.3%;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -3px 0.3em 0 0;
  min-height: 1.18181818em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  float: left;
  width: 100%;
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
.calendar {
  position: absolute;
  z-index: 100;
  border: 1px solid #666;
  background: #333;
  text-align: right;
  line-height: 2;
  color: #fff !important;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.calendar > table {
  margin: 3px;
  border-spacing: 0;
  border-collapse: collapse;
}
.calendar th {
  color: #eee;
  text-align: center;
  font-weight: 700;
}
.calendar tbody {
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #666;
}
td.published {
  color: #7be;
  font-weight: 700;
}
td.show {
  background: #7be;
  color: #fff;
  font-weight: 700;
}
.calendar th > a,
.calendar td > a {
  display: block;
  padding: 0 6px 1px 6px;
  color: inherit;
  text-decoration: none;
}
.calendar th > a:hover,
.calendar td > a:hover {
  background: #666;
  color: #fff;
}
.calendar td.show {
  background: #87004e;
}
.calendar td.show a:hover {
  background: none;
}
/*** mobile-navi-multilevel-right-left.less 2014-11-03 ***/
.navi {
  display: none;
}
#mobile-navi {
  position: fixed;
  top: 0;
  width: 300px;
  height: 100%;
  z-index: 2000;
  overflow: scroll;
  background: #ebbca0;
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
#mobileservices {
  float: right;
  margin-right: 4vw;
  text-align: right;
  margin-top: 22px;
  margin-bottom: 30px;
}
#mobileservices .meta {
  display: block;
  color: #3a2d29;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  font-family: 'RobotoCondensedMedium', helvetica, sans-serif;
  text-decoration: none;
}
#mobileservices .meta.service_custimprint {
  margin-top: 22px;
}
.mobile-homelink {
  float: right;
  margin-top: 80px;
  margin-right: 4vw;
  color: #3a2d29;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  font-family: 'RobotoCondensedMedium', helvetica, sans-serif;
  text-decoration: none;
}
.mobile-homelink:hover,
.mobile-homelink:focus {
  color: #3a2d29;
}
.togglenavigation {
  position: fixed;
  top: 20px;
  display: block;
  width: 44px;
  height: 32px;
  z-index: 2001;
  cursor: pointer;
  color: #ebbca0 !important;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
}
.togglenavigation:active > span {
  background: #e4a47e !important;
}
.togglenavigation > span {
  left: 0;
  top: 50%;
  position: absolute;
  display: block;
  height: 8px;
  width: 44px;
  margin-top: -4px;
  background: currentColor;
  border: 2px solid #3a2d29;
  border-radius: 10px;
  box-sizing: border-box;
}
.togglenavigation > .tline-1 {
  margin-top: -16px;
}
.togglenavigation > .tline-4 {
  margin-top: 8px;
}
.cb-toggle-target-active .togglenavigation > .tline-1,
.cb-toggle-target-active .togglenavigation > .tline-4 {
  opacity: 0;
}
.cb-toggle-target-active .togglenavigation > .tline-2,
.cb-toggle-target-active .togglenavigation > .tline-3 {
  transition: transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
}
.cb-toggle-target-active .togglenavigation > .tline-2 {
  transform: rotate(-45deg);
}
.cb-toggle-target-active .togglenavigation > .tline-3 {
  opacity: 1;
  transform: rotate(45deg);
}
/* RIGHT */
#mobile-navi {
  right: -300px;
}
.togglenavigation {
  right: 4%;
}
body.cb-toggle-target-active #mobile-navi {
  right: 0;
}
/* LEFT */
#mobile-navi .navi {
  float: left;
  display: block;
  width: 100%;
  text-align: right;
}
#mobile-navi .navi .item {
  width: 100%;
  float: left;
  position: relative;
}
#mobile-navi .navi .item .menu {
  display: block;
  color: #3a2d29;
  padding: 0 4vw;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  font-family: 'RobotoCondensedMedium', helvetica, sans-serif;
  text-decoration: none;
}
#mobile-navi .navi .item .menu.path {
  color: #fff;
}
.desk {
  max-width: 92%;
}
#home {
  width: 140px;
  margin-left: -70px;
}
@media only screen and (max-width: 750px) {
  #expo {
    padding-bottom: 250px;
  }
  #slides {
    height: 250px !important;
  }
  #slides .slide {
    width: 750px;
    height: 250px !important;
    left: 50%;
  }
  #slides .slide img {
    margin-left: -375px;
  }
}
div.sub1 {
  float: right;
  display: block;
  margin: 4px 0;
}
div.sub1 > .item {
  float: left;
  border-right: 2px solid #fff;
  padding: 0 5px;
}
div.sub1 > .item.init {
  padding-left: 0;
}
div.sub1 > .item.exit {
  padding-right: 0;
  border: none;
}
div.sub1 > .item > .menu {
  display: block;
  color: #fff;
  text-decoration: none;
  font-family: 'RobotoCondensedMedium', helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  line-height: 12px;
}
div.sub1 > .item > .menu:hover,
div.sub1 > .item > .menu:focus,
div.sub1 > .item > .menu.path {
  color: #ebbca0;
}
@media (max-width: 1024px) {
  .footunit {
    margin-top: 20px;
  }
  #cmsbox {
    margin-bottom: 25px;
  }
  #cmsbox .meta.auth {
    margin-top: 0;
  }
}
@media (max-width: 1024px) {
  .wrapper {
    font-size: 12px;
    line-height: 1.16666667;
  }
  h1,
  h2 {
    font-size: 22px;
    line-height: 1.09090909;
  }
  h3 {
    font-size: 18px;
    line-height: 1.11111111;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area > .slim .part {
  width: 100%;
}
@media (max-width: 1024px) {
  .unit {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
@media (max-width: 1024px) {
  .part {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 90px !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
/*# sourceMappingURL=./screen-small.css.map */